import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { AccordeonComponent } from './components/accordeon/accordeon.component';
import { AplicacionComponent } from './components/aplicacion/aplicacion.component';

const routes: Routes = [
  {path:'', component: AplicacionComponent},
  {path:'aplicacion', component: AplicacionComponent},
  {path:'**', component:  AplicacionComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
