import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-aplicacion',
  templateUrl: './aplicacion.component.html',
  styleUrls: ['./aplicacion.component.css']
})
export class AplicacionComponent implements OnInit {
  title = 'CatalogApp';
  constructor() { }

  ngOnInit(): void {
  }

}
