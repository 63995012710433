
  <div class="container">
    <div class="row">
      <div class="col-lg-6 offset-lg-3" style="border-radius:50px;">
          <div class="card text-center mt-1" style="border-radius: 35px;">
            <div class="card-title mt-1">
              <p class="titulo">APPs Interactivas</p>
            </div>
          </div>
      </div>
    </div>
  
  <div class="row mt-2">
    <div class="col-lg-6 offset-lg-3 text-left">
            <div class="fondoItem">  
              <p><a class="btn btn-primary btn-block btn-sm" href="https://granja-paulohidalgo-app3antonios.netlify.app/" target="_blank">Animales de la Granja</a></p>
              <p><a class="btn btn-primary btn-block btn-sm" href="https://planetas-paulohidalgo-app3antonios.netlify.app/" target="_blank">Ordena los Planetas</a></p>
              <p><a class="btn btn-primary btn-block btn-sm" href="https://numeroletras-paulohidalgo-app3a.netlify.app/" target="_blank">Números a Letras</a></p>
                    <!--<p><a class="btn btn-primary btn-block btn-sm" href="https://tirardados-app3a.netlify.app/" target="_blank">Tirar Dados</a></p>
                    <p><a class="btn btn-primary btn-block btn-sm" href="https://convertidormonedas-app3a.netlify.app/" target="_blank">Convertidor de Monedas</a></p>
                    <p><a class="btn btn-primary btn-block btn-sm" href="https://calculatorimc-app3a.netlify.app/" target="_blank">Calculador IMC</a></p>-->
                    <!--<p><a class="btn btn-primary btn-block btn-sm" href="https://mantenedorempleados-rhidalgo.netlify.app/" target="_blank">Mantenedor Empleados</a></p>-->
                    <!--<p><a class="btn btn-primary btn-block btn-sm" href="https://empleados-firebase-app3a.netlify.app/" target="_blank">Mantenedor Empleados - Firebase</a></p>
                    <p><a class="btn btn-primary btn-block btn-sm" href="https://tarjetacredito-app3a.netlify.app/" target="_blank">Tarjeta Crédito - Firebase</a></p>
                    <p><a class="btn btn-primary btn-block btn-sm" href="https://obtenerimagenpixabay-app3a.netlify.app/" target="_blank">Buscar Foto - Pixabay</a></p>
                    <p><a class="btn btn-primary btn-block btn-sm" href="https://obtenerclimaapi-app3a.netlify.app/" target="_blank">Obtener Clima - Openweathermap</a></p>
                    <p><a class="btn btn-primary btn-block btn-sm" href="https://agregarcitas-app3a.netlify.app/" target="_blank">Agregar Citas</a></p>
                    <p><a class="btn btn-primary btn-block btn-sm" href="https://agregarpresupuesto-app3a.netlify.app/" target="_blank">Agregar Presupuesto</a></p>
                    <p><a class="btn btn-primary btn-block btn-sm" href="https://pregyresp-app3a.netlify.app/" target="_blank">Preguntas y Respuestas</a></p>-->              
            </div>
        </div>
    </div>
  </div>

