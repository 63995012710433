<div class="container_flex">
  <div class="imagen">
    <img src="./../assets/logoOficial.png" width="9%">
  </div>
  
  <div layout="row">
    <app-aplicacion></app-aplicacion>
  </div>  
</div>

